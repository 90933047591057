<template>
  <div id="app">
    <el-row style="margin-bottom: 5px">
      <el-col :span="3">
        <el-select
          v-model="city"
          placeholder="Хотоо сонгоно уу?"
          @change="changeCity"
          size="mini"
        >
          <el-option
            v-for="(city, indexCity) in cities"
            :key="indexCity"
            :label="city.value"
            :value="city.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-select
          v-model="district"
          placeholder="Дүүргээ сонгоно уу?"
          @change="changeDisrict"
          size="mini"
        >
          <el-option
            v-for="(district, indexDistrict) in districts"
            :key="indexDistrict"
            :label="district.value + '(' + district.count + ')'"
            :value="district.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="9">
        <el-select
          v-model="horoo"
          placeholder="Хороогоо сонгоно уу?"
          size="mini"
        >
          <el-option
            v-for="horoo in horoos"
            :key="horoo.name"
            :label="horoo.name + ' (' + horoo.countOfAddress + ')'"
            :value="horoo.name"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="8">
        <el-row>
          <el-col :span="6"
            ><h5 style="margin-top: 7px;">Нийт хаягаас хайх:</h5></el-col
          >
          <el-col :span="15">
            <el-input
              style="width: 200px"
              placeholder="Хайх хаягаа оруулна уу?"
              size="mini"
              @change="search"
              clearable
              v-model="value"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">
        <div class="widget shady-panel">
          <div class="body">
            <el-table :data="addresses">
              <el-table-column
                prop="full_address"
                label="Бүтэн хаяг"
              ></el-table-column>
              <el-table-column :min-width="20" label="Бүтэн хаяг">
                <el-button
                  slot-scope="scope"
                  @click="handleClick(scope.row)"
                  size="small"
                  >Засах</el-button
                >
              </el-table-column>
            </el-table>
            <!-- <el-radio-group v-model="selectedAddress" size="mini">
                <el-radio
                  v-for="(address, index) in addresses"
                  :label="address"
                  :key="index"
                  @change="handleSelect"
                  style="width:60% ; margin:2px"
                >
                  <span class="catName">{{ address.full_address }}</span>
                </el-radio>
              </el-radio-group> -->
          </div>
        </div>
      </el-col>
      <el-col :span="5">
        <gmap-map
          :center="center"
          :zoom="12"
          :options="options"
          :draggable="false"
          style="width:60vw;  height: 80vh; margin-top: 5px;margin-left:25%"
        >
          <div>
            <div v-for="(item, index) in paths" :key="index">
              <gmap-polygon
                v-if="item.podcode === selectedPod.podcode"
                :paths="item.paths"
                :options="optionsPoly1"
                :editable="false"
                @click="focusCenterPlot(item)"
              >
              </gmap-polygon>
              <gmap-polygon
                v-else
                :paths="item.paths"
                :options="optionsPoly"
                :editable="false"
                @click="focusCenterPlot(item)"
              >
              </gmap-polygon>
            </div>
            <gmap-marker
              v-for="marker in addresses"
              :key="marker.id"
              :position="marker.pos"
              @click="onClickMarker(marker)"
              :title="marker.full_address + ' ' + marker.bairnote"
              :draggable="true"
              @position_changed="updatePlaceM1(marker.id, $event)"
            >
            </gmap-marker>
          </div>
        </gmap-map>
      </el-col>
    </el-row>
    <address-dialog
      :addressData="this.selectedAddressDialog"
      :type="this.type"
      :showAddressDialog="showAddressDialog"
      :updateFunction="update"
      :closeFunction="closeDialog"
    ></address-dialog>
    <!-- <div v-if="showAddressDialog">
    </div> -->
    <!-- {{addresses[5]}} -->
    <el-button
      type="danger"
      style="float: right; margin-top: 15px; margin-right: 15px;"
      @click="updateLoc"
      >Хадгалах</el-button
    >
  </div>
</template>

<script>
import services from "../helpers/services.js";
import AddressDialog from "./AddressDialog";
import { getUserName } from "../utils/auth";
export default {
  name: "Address",
  components: {
    AddressDialog
  },
  created() {
    this.getCities();
    this.getDistricts();
    this.changeBrand();
    services.getPods("*", "*", "*").then(data => {
      for (var i = 0; i < data.length; i++) {
        var temp1 = [];
        var poligon = data[i].poligon.split(" ");
        for (var j = 0; j < poligon.length; j = j + 2) {
          var temp = {
            lat: 0,
            lng: 0
          };
          temp.lat = parseFloat(poligon[j]);
          temp.lng = parseFloat(poligon[j + 1]);
          temp1.push(temp);
        }
        data[i].paths = temp1;
      }
      this.paths = data;
    });
    services.getAddressByPod("*", "*").then(data => {
      data.forEach(element => {
        element.pos = {
          lat: parseFloat(element.lat),
          lng: parseFloat(element.lon)
        };
      });
      this.addresses = data;
    });
    getUserName().then(name => {
      this.user = name.attributes.email.split("@")[0];
    });
  },
  data() {
    return {
      cities: [],
      city: "УБ",
      showAddressDialog: false,
      selectedAddressDialog: {},
      horoos: [],
      horoo: "",
      districts: [],
      type: false,
      district: "",
      selectedAddress: [],
      user: "",
      value: "",
      updatedLocations: [],
      addresses: [],
      newPod: false,
      newPodObj: {},
      brands: [],
      brand: "TOKTOK",
      outlets: [],
      outlet: "",
      paths: [],
      selectedPod: {},
      options: {
        scrollwheel: true,
        mapTypeControl: true,
        streetViewControl: false,
        minZoom: 4,
        maxZoom: 20,
        gestureHandling: "greedy"
      },
      center: {
        lat: 47.9174,
        lng: 106.9137
      },
      points: [
        { lat: 47.89678628, lng: 106.8961749 },
        { lat: 47.8987788, lng: 106.8962714 },
        { lat: 47.89896942, lng: 106.9082716 },
        { lat: 47.89616765, lng: 106.9081697 },
        { lat: 47.89598062, lng: 106.9018611 },
        { lat: 47.89536917, lng: 106.9018826 }
      ],
      optionsPoly: {
        fillColor: "#000000",
        strokeColor: "#FF0000",
        strokeOpacity: 0.9,
        fillOpacity: 0.1
      },
      optionsPoly1: {
        fillColor: "#000000",
        strokeColor: "#00FF00",
        strokeOpacity: 0.9,
        fillOpacity: 0.1
      }
    };
  },
  methods: {
    getCities() {
      services.getCities().then(response => {
        this.cities = response.data.data;
      });
    },
    changeCity() {
      this.getDistricts();
    },
    getDistricts() {
      services.getDistricts(this.city).then(response => {
        this.districts = response.data.data;
      });
    },
    changeDisrict() {
      services.getHoroos(this.city, this.district).then(response => {
        this.horoos = response.data.data;
      });
    },
    handleSelect() {
      this.addresses = Array(this.selectedAddress);
      this.value = "";
    },
    update() {
      this.apart.user = this.user;
      services.update(this.apart).then(data => {
        if (data.result === "updated") {
          this.$notify({
            title: "Амжилттай.",
            message: "Хаягийг амжилттай засварлалаа.",
            type: "success"
          });
        } else {
          this.$notify({
            title: "Анхааруулга.",
            message: "Хаягийг засварлах үйлдэл амжилтгүй боллоо.",
            type: "warning"
          });
        }
        this.dialogFormVisible = false;
      });
    },
    search() {
      services
        .getAddress(this.value, this.district, this.horoo)
        .then(response => {
          response.data.forEach(element => {
            element.pos = {
              lat: parseFloat(element.latitude),
              lng: parseFloat(element.longitude)
            };
          });
          this.addresses = response.data;
          this.selectedAddress = [];
        });
    },
    handleClick(data) {
      this.selectedAddressDialog = JSON.parse(JSON.stringify(data));
      this.showAddressDialog = true;
    },
    updateLoc() {
      var it = 0;
      this.updatedLocations.forEach(element => {
        element.user = this.user;
        services
          .updateAddresLoc(element)
          .then(data => {
            it++;
            if (it === this.updatedLocations.length) {
              this.$notify({
                title: "Амжилттай",
                message: "Амжилттай хадгалагдлаа.",
                type: "success"
              });
              if (data == "") {
                this.updatedLocations = [];
              }
              this.updatedLocations = [];
            }
          })
          .catch(error => {
            this.$notify({
              title: "Алдаа гарлаа",
              message: "Алдаа: " + error,
              type: "error"
            });
          });
      });
    },
    updatePlaceM1(id, loc) {
      var indexO = this.updatedLocations.findIndex(x => x.id === id);
      if (indexO === -1) {
        var temp = {
          id: id,
          lat: loc.lat(),
          lon: loc.lng()
        };
        this.updatedLocations.push(temp);
      } else {
        this.updatedLocations[indexO].lat = loc.lat();
        this.updatedLocations[indexO].lon = loc.lng();
      }
    },
    reload() {
      this.selectedPod = {};
      let outlet = this.outlet;
      if (outlet === "") {
        outlet = "*";
      }
      let brand = this.brand;
      if (brand === "") {
        brand = "*";
      }
      services.getPods(brand, outlet, "*").then(data => {
        for (var i = 0; i < data.length; i++) {
          var temp1 = [];
          var poligon = [];
          if (data[i].poligon) {
            poligon = data[i].poligon.split(" ");
          }
          for (var j = 0; j < poligon.length; j = j + 2) {
            var temp = {
              lat: 0,
              lng: 0
            };
            temp.lat = parseFloat(poligon[j]);
            temp.lng = parseFloat(poligon[j + 1]);
            temp1.push(temp);
          }
          data[i].paths = temp1;
        }
        this.paths = data;
        this.newPod = false;
      });
    },
    focusCenterPlot(pod) {
      this.selectedPod = pod;
      services.getAddressByPod("*", this.selectedPod.podcode).then(data => {
        data.forEach(element => {
          element.pos = {
            lat: parseFloat(element.lat),
            lng: parseFloat(element.lon)
          };
        });
        this.addresses = data;
      });
    },
    changeOutlet() {
      services.getPods(this.brand, this.outlet, "*").then(data => {
        for (var i = 0; i < data.length; i++) {
          var temp1 = [];
          var poligon = [];
          if (data[i].poligon) {
            poligon = data[i].poligon.split(" ");
          }
          for (var j = 0; j < poligon.length; j = j + 2) {
            var temp = {
              lat: 0,
              lng: 0
            };
            temp.lat = parseFloat(poligon[j]);
            temp.lng = parseFloat(poligon[j + 1]);
            temp1.push(temp);
          }
          data[i].paths = temp1;
        }
        this.paths = data;
      });
    },
    changeBrand() {
      services.getOutletsByPods(this.brand).then(data => {
        this.outlets = data;
      });
      services.getPods(this.brand, "*", "*").then(data => {
        for (var i = 0; i < data.length; i++) {
          var temp1 = [];
          var poligon = [];
          if (data[i].poligon) {
            poligon = data[i].poligon.split(" ");
          }
          for (var j = 0; j < poligon.length; j = j + 2) {
            var temp = {
              lat: 0,
              lng: 0
            };
            temp.lat = parseFloat(poligon[j]);
            temp.lng = parseFloat(poligon[j + 1]);
            temp1.push(temp);
          }
          data[i].paths = temp1;
        }
        this.paths = data;
      });
    },
    change() {
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    onClickMarker(data) {
      this.selectedAddressDialog = JSON.parse(JSON.stringify(data));
      this.showAddressDialog = true;
    },
    closeDialog() {
      this.selectedAddressDialog = null;
      this.showAddressDialog = false;
    }
  }
};
</script>

<style scoped>
.searchResult ul {
  position: relative;
  font-weight: bold;
  color: gray;
  font-size: 11px;
}
</style>
